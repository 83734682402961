
function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_HOME = '';
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_OFFER = '/offer';
const ROOTS_SETTINGS = '/settings';
const ROOTS_STANDUP= '/standup';
const ROOTS_TODO = '/todo';
const ROOTS_COMPANY = '/company';
const ROOTS_TIME = '/time';
const ROOTS_REVIEW = '/review';
const ROOTS_SALARY = '/salary';
const ROOTS_FLIERS = '/fliers';
const ROOTS_CONTRACT = '/contract';
const ROOTS_INVITATION = '/d';
const ROOTS_FAQ = '/faq';
const ROOTS_REWARD = '/reward';
const ROOTS_ONBOARD = '/onboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_HOME = {
  root: ROOTS_HOME,
  application: path(ROOTS_HOME, '/application'),
  career: path(ROOTS_HOME, '/career'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  process: path(ROOTS_DASHBOARD, '/process'),
  schedule: path(ROOTS_DASHBOARD, '/schedule'),
  result: path(ROOTS_DASHBOARD, '/result'),
  wait: path(ROOTS_DASHBOARD, '/wait'),
  crew: path(ROOTS_DASHBOARD, '/crew'),
  searching: path(ROOTS_DASHBOARD, '/searching'),
  suggestion: path(ROOTS_DASHBOARD, '/suggestion'),
  matchingStart: path(ROOTS_DASHBOARD, '/matching-start'),
};

export const PATH_OFFER = {
  root: ROOTS_OFFER,
};

export const PATH_SETTINGS = {
  root: ROOTS_SETTINGS,
};

export const PATH_SALARY = {
  root: ROOTS_SALARY,
  submit: path(ROOTS_SALARY, '/submit'),
  check: path(ROOTS_SALARY, '/check'),
  checkDetail: path(ROOTS_SALARY, '/check/detail'),
  complete: path(ROOTS_SALARY, '/complete'),
  completeDetail: path(ROOTS_SALARY, '/complete/detail'),
}

export const PATH_STANDUP = {
  root: ROOTS_STANDUP,
  detail: path(ROOTS_STANDUP, '/detail'),
};

export const PATH_TODO = {
  root: ROOTS_TODO,
  detail: path(ROOTS_TODO, '/detail'),
};

export const PATH_COMPANY = {
  root: ROOTS_COMPANY,
};

export const PATH_TIME = {
  root: ROOTS_TIME,
};

export const PATH_REVIEW = {
  root: ROOTS_REVIEW,
  detail: path(ROOTS_REVIEW, '/detail'),
};

export const PATH_FLIERS = {
  root: ROOTS_FLIERS,
  company: path(ROOTS_FLIERS, '/company'),
  manage: path(ROOTS_FLIERS, '/manage'),
};

export const PATH_CONTRACT = {
  root: ROOTS_CONTRACT,
};

export const PATH_INVITATION = {
  root: ROOTS_INVITATION,
  career: path(ROOTS_INVITATION, '/career'),
  onboard: path(ROOTS_INVITATION, '/onboard'),
};

export const PATH_FAQ = {
  root: ROOTS_FAQ,
};

export const PATH_REWARD = {
  root: ROOTS_REWARD,
};

export const PATH_ONBOARD = {
  root: ROOTS_ONBOARD,
};
